/* global
 autosize
 bsCustomFileInput
 checkRedirect
 gettext
 initHtmlTextarea
 $x
 */


// #############################################################################
// GLOBAL VARS

const $body = $("body");

// #############################################################################
// BETTER FOCUS

const $betterFocus = new $x.BetterFocus();
$betterFocus.init();

// #############################################################################
// TOOLTIP

$("[data-toggle=tooltip]").tooltip();

// #############################################################################
// FORM: MULTIPLE SELECT ITEMS

$x.initMultipleSelectItems = () => {
  const $inputs = document.querySelectorAll("[data-multiple-select-item]");

  $inputs.forEach(function ($input) {
    if ($input.$multipleSelectItem) {
      return;
    }

    $input.$multipleSelectItem = new $x.MultipleSelectItems($input);
    $input.$multipleSelectItem.init();
  });
};

// #############################################################################
// FORM

$x.initFormDefaults = function ($parent = $body) {
  // File
  bsCustomFileInput.init();

  // Autosize
  autosize($("textarea", $parent));

  // HTML CKEditor
  initHtmlTextarea($parent);

  // Range
  $("[type=range]", $parent).formRange();

  // Ajax upload
  const $ajaxUpload = new $x.AjaxUpload($("[data-ajax-upload]", $parent), {
    onUploadCompleted: function ($upload, $data) {
      $x.replaceHtml($data);
    },
  });

  $ajaxUpload.init();

  // File tree
  $("[data-file-tree]", $parent).formFileTree();

  // Form set
  $("[data-form-set]", $parent).formSet();

  $x.initMultipleSelectItems();

  return {
    ajaxUpload: $ajaxUpload,
  };
};

const $formDefaults = $x.initFormDefaults();

// Validation

$("[data-form]").formValidation({
  beforeSubmit: function () {
    $formDefaults.ajaxUpload.reset();
  },
  afterSubmit: function ($xhr, $form, $data) {
    if ($data.submit === "success") {
      if ($data.redirect) {
        checkRedirect($data);
      } else {
        $x.replaceHtml($data);

        if ($data.toaster) {
          $body.toaster("updateToaster", $data.toaster);
        }
      }
    }
  },
});

// Wizard

$("[data-form-wizard]").formWizard();


// #############################################################################
// DATA TABLE

function datatable_data_to_json (data_dict) {
  const filter_data_dict = {};
  function generate_filter_data (data_dict, prefix) {
    prefix = typeof prefix !== "undefined" ? prefix : false;
    $.each(data_dict, function (key, val) {
      if (prefix) { key = `${prefix}[${key}]`; }
      if (Array.isArray(val)) {
        generate_filter_data(val, key);
      } else if (val instanceof Object) {
        generate_filter_data(val, key);
      } else {
        filter_data_dict[key] = String(val);
      }
    });
  }
  generate_filter_data(data_dict);
  return filter_data_dict;
}

const $dataTables = document.querySelectorAll("[data-table]");

$dataTables.forEach(function ($item) {
  $item.$datatable = new $x.DataTables($item, {
    api: function ($table, $api) {
      // API: https://datatables.net/reference/api/

      const $input = $table.querySelector("[data-multiple-select-item]");

      $api.on("draw", function () {
        $("[data-toggle=tooltip]", $table).tooltip();

        if ($input) {
          $input.$multipleSelectItem.reset();
        }
      });

      $body.on("click", "form[data-form='data-table-function']", function (e) {
        console.log(this);
        const filter_data_dict = datatable_data_to_json($api.columns().ajax.params());
        const filter_data_field = $("input[name='filter_data_field']");

        if (!filter_data_field.length) {
          $("<input />").attr("type", "hidden")
            .attr("name", "filter_data_field")
            .attr("value", JSON.stringify(filter_data_dict))
            .appendTo($(this));
        }
        filter_data_field.val(JSON.stringify(filter_data_dict));
      });
    },
    customizeCSV: function (csv) {
      // For customization read https://datatables.net/reference/button/csv

      return csv;
    },
    rowGroupStartRender: function ($table, $rows, html) {
      return html;
    },
  });
});

// #############################################################################
// FULL CALENDAR

const $calendars = document.querySelectorAll("[data-calendar]");

$calendars.forEach(function ($item) {
  $item.$calendar = new $x.FullCalendar($item);
});

// #############################################################################
// CHART JS

const $charts = document.querySelectorAll("[data-chartjs]");

$charts.forEach(function ($element) {
  const $chartJS = new $x.ChartJS($element);
  $chartJS.init();
});

// #############################################################################
// MODAL

// #############################################################################
// MODAL

// re-enable all during modal-open disabled data-modal-links
function removeDisabledAfterModalOpened() {
  document.querySelectorAll("[data-modal-link][data-modal-link-disabled]").forEach(function ($el) {
    $el.classList.remove("disabled");
    $el.removeAttribute("data-modal-link-disabled");
  });
}

$x.beforeModalOpenDefault = function ($modal, $data, $el) {
  removeDisabledAfterModalOpened();

  if ($modal.find(".companies-assignment-modal").length) {
    before_companies_assignment_modal_open();
  }

  if ($data.submit === "error") {
    if ($data.toaster) {
      $("body").toaster("updateToaster", $data.toaster);
    }
  }

  $(".multi-select").multiSelect({
    cssClass: "w-100",
    selectableHeader: "<input type='text' class='search-input select2-search__field w-100 mb-1 radius-3' autocomplete='off' placeholder='Filter'>",
    selectionHeader: "<input type='text' class='search-input select2-search__field w-100 mb-1 radius-3' autocomplete='off' placeholder='Filter'>",
    afterInit: function(ms) {
      const that = this;
      const $selectableSearch = that.$selectableUl.prev();
      const $selectionSearch = that.$selectionUl.prev();
      const selectableSearchString = "#" + that.$container.attr("id") + " .ms-elem-selectable:not(.ms-selected)";
      const selectionSearchString = "#" + that.$container.attr("id") + " .ms-elem-selection.ms-selected";

      that.qs1 = $selectableSearch.quicksearch(selectableSearchString).on("keydown", function(e) {
        if (e.which === 40) {
          that.$selectableUl.focus();
          return false;
        }
      });

      that.qs2 = $selectionSearch.quicksearch(selectionSearchString)
        .on("keydown", function(e) {
          if (e.which === 40) {
            that.$selectionUl.focus();
            return false;
          }
        });
    },
    afterSelect: function() {
      this.qs1.cache();
      this.qs2.cache();
    },
    afterDeselect: function() {
      this.qs1.cache();
      this.qs2.cache();
    },
  });
};

$x.onModalOpenDefault = function ($modal) {
  $("[autofocus]", $modal).focus();
  $("[data-toggle=tooltip]", $modal).tooltip();

  const $djangoSelect2 = $(".django-select2");

  if ($djangoSelect2.length) {
    // Init Select2 Fields in the modal dialog, only if there is any django-select2 field.
    $djangoSelect2.djangoSelect2({
      dropdownParent: $("#modal"),
    });
  }

  const $formDefaults = $x.initFormDefaults($modal);

  // Validation

  $("[data-form]", $modal).formValidation({
    beforeSubmit: function () {
      $formDefaults.ajaxUpload.reset();
    },
    afterSubmit: function ($xhr, $form, $data) {
      if ($data.submit === "success") {
        $modal.modal("hide");

        if ($data.reload_page) {
          location.reload();
        }
        if ($data.redirect) {
          checkRedirect($data);
        } else {
          $x.replaceHtml($data);

          if ($data.toaster) {
            $body.toaster("updateToaster", $data.toaster);
          }

          $dataTables.forEach(function ($item) {
            $item.$datatable.reload();
          });

          $calendars.forEach(function ($item) {
            $item.$calendar.$api.refetchEvents();
          });
        }
      }
    },
  });

  // Wizard

  $("[data-form-wizard]", $modal).formWizard();
};

$x.onModalCloseDefault = function ($modal, $data, $el) {
  if ($modal.find(".companies-assignment-modal").length) {
    on_companies_assignment_modal_close();
  }
};

$x.delegateEvent.on(document, "click", "[data-modal-link]", function (e) {
  e.preventDefault();

  // set all data-modal-links (which are not already disabled) disabled -> so only one modal can be opened at a time
  document.querySelectorAll("[data-modal-link]:not(.disabled):not([disabled])").forEach(function ($el) {
    $el.setAttribute("data-modal-link-disabled", "data-modal-link-disabled");
    $el.classList.add("disabled"); // only allow data-modal-link to be clicked once until modal opened
  });

  $x.modal.open(
    this.href,
    {
      beforeModalOpen: $x.beforeModalOpenDefault,
      onModalOpen: $x.onModalOpenDefault,
      onModalClose: $x.onModalCloseDefault,
    },
    this
  );
});

// #############################################################################
// DOWNLOAD BLOB

$x.delegateEvent.on(document, "click", "[data-download]", function (e) {
  e.preventDefault();

  const $downloadBlob = new $x.DownloadBlob({
    onDownloadStarted: function ($data) {
      $body.toaster("updateToaster", $data.toaster);

      $dataTables.forEach(function ($item) {
        $item.$datatable.reload();
      });
    },
  });

  $downloadBlob.download(this.href);
});

// #############################################################################
// CLIPBOARD

$body.clipBoard({
  selector: "[data-clipboard]",
});

// #############################################################################
// TOASTER

$body.toaster({
  selector: "[data-toaster]",
});

// #############################################################################
// AUTO UPDATE HTML CONTENT

$body.autoUpdateHtmlContent({
  selector: "[data-update-html-content]",
});

$(".date-input").datepicker({
  endDate: Date.now(),
  autoHide: true,
  language: "de-AT",
});


$("#id_popup_modal").modal("show");

// ##############################################
// QR TEXT
$body.on("blur", "#id_catererform-qr_text", function () {
  const qr_details = new QRTextDetails($(this).val());
  if (qr_details.isValid) {
    $("#id_catererform-qr_id").val(qr_details.qrId);
  } else {
    $(`#${this.id}_error`).addClass("d-block").html(gettext("Please enter a valid qr text"));
  }
}).on("input", "#id_catererform-qr_text", function () {
  $(`#${this.id}_error`).removeClass("d-block").html();
});

class QRTextDetails {
  constructor (qrText) {
    this.qrText = qrText;
  }

  get qrCodeParts() {
    return this.qrText.split("_");
  }

  get isValid() {
    return this.qrCodeParts.length >= 11;
  }

  get qrId () {
    const codeTypePosition = 1;
    const codePosition = 11;
    const codeTypeAT0 = "AT0";
    const textParts = this.qrCodeParts;

    const type = textParts[codeTypePosition].split("-")[1];

    if (type === codeTypeAT0) {
      return `${type}:${textParts[codePosition].split("-")[0]}`;
    } else {
      return `${type}:${textParts[codePosition]}`;
    }
  }
}
// ##################################################

let assign_company_puffer = {};

function before_companies_assignment_modal_open() {
  $(".refusal-reasons-container").addClass("d-none");
  $(".assign-company-content.active").find("select").each(function (idx, item) {
    assign_company_puffer[item.id] = $(item).val();
  });
}

function on_companies_assignment_modal_close() {
  assign_company_puffer = {};
}

$body.on("shown.bs.tab", ".assign-company-tabs", function (event) {
  if (this.id === "id_multi_validation_tab") {
    $("#id_allcompaniesassignmentform-is_all_companies").val("True");
  } else {
    $("#id_allcompaniesassignmentform-is_all_companies").val("False");
  }
  $(".assign-company-content.active").find("select").each(function (idx, item) {
    assign_company_puffer[item.id] = $(item).val();
  });
  $(".assign-company-content:not(.active)").find("select").each(function (idx, item) {
    const item_id = item.id;
    if (item_id in assign_company_puffer) {
      $(item).val(assign_company_puffer[item_id]);
      delete assign_company_puffer[item_id];
    }
  });
  $(".refusal-reasons-container").addClass("d-none").find("select").val("");
});

$body.on("change", ".reported-company-assignment", function () {
  // 3 is refused status of caterer2company
  const refused_status = $(".assign-company-content.active")
    .find(".reported-company-assignment option:selected")
    .filter(function () { return $(this).val() === "3"; });
  console.log(refused_status);
  if ($(this).val() === "3") {
    $(".refusal-reasons-container").removeClass("d-none");
  } else if (refused_status.length === 0) {
    $(".refusal-reasons-container").addClass("d-none");
  }
});

$body.on("click", "#id_global_companies_select_all", function () {
  $(this).parents(".container").find("input[type='checkbox'].company").prop("checked", this.checked);
});
